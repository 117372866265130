import './ContentBuilderTemplate.scss';

import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import * as yup from 'yup';
import { Message } from 'yup/lib/types';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Article } from '../../Article/Article';
import { Button } from '@dataartdev/uikit/Button';
import { Checkbox } from '@dataartdev/uikit/Checkbox';
import { usePropsHandler } from '../../EventInterceptor/usePropsHandler';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { MemberCard } from '@dataartdev/uikit/MemberCard';
import { Modal } from '@dataartdev/uikit/Modal';
import { TextField } from '@dataartdev/uikit/TextField';
import { Typography } from '@dataartdev/uikit/Typography';
import { mutationLocationsSalesNewForm, mutationMainAllForm } from 'network';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import { cnContentBuilderTemplate } from './index';
import { usePagePath } from '../../../hooks/usePagePath';
import FormBuilder from '../../Form/FormBuilder';
import { useFormik } from 'formik';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import { dictionariesSelector } from '../../../../store';
import 'react-phone-number-input/style.css';
import { IRoot3 } from '../models/CTI_3.props';
import sbjs from 'sourcebuster';

sbjs?.init();

export const schemaLetsTalkAll = (
  emailError: Message,
  phoneError: Message,
  companyMinError: Message,
  companyMaxError: Message,
  requiredError: Message
) =>
  yup.object({
    firstName: yup
      .string()
      .min(1, companyMinError)
      .max(50, companyMaxError)
      .required(requiredError)
      .nullable(),
    lastName: yup
      .string()
      .min(1, companyMinError)
      .max(50, companyMaxError)
      .required(requiredError)
      .nullable(),
    email: yup
      .string()
      .email(emailError)
      .max(100, companyMaxError)
      .required(requiredError)
      .nullable(),
    job: yup
      .string()
      .min(2, companyMinError)
      .max(100, companyMaxError)
      .nullable(),
    company: yup
      .string()
      .min(2, companyMinError)
      .max(100, companyMaxError)
      .required(requiredError)
      .nullable(),
    phone: yup
      .string()
      .test('is-valid-phone', phoneError || 'Invalid phone number', value =>
        value ? isValidPhoneNumber(value) : true
      )
      .nullable(),
    message: yup
      .string()
      .min(10, companyMinError)
      .max(1000, companyMaxError)
      .required(requiredError)
      .nullable(),
    newsletter: yup.boolean(),
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'You must accept')
      .required(requiredError),
  });

export const ContentBuilderTemplate: React.FC<IRoot3> = props => {
  const {
    id,
    type,
    title,
    text,
    member,
    form,
    contentId,
    dictionariesForm,
    pageId,
    aliases,
    // trackId,
    // onSubmitForm,
  } = usePropsHandler(cnContentBuilderTemplate(), props);

  const typeNumber = type.toString();
  const [successForm, setSuccessForm] = useState(false);
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const pagePath = usePagePath();
  const sendDataToGTM = useGTMDispatch();
  const cameFrom = Object.entries(sbjs.get.current)
    .map(([key, value]) => `${key}: ${value}`)
    .join(' | ');
  const utm = sbjs.get.current.typ || '';
  const { companySiteForm } = useSelector(dictionariesSelector) || {};

  const {
    companySiteFormContactUsSubscribtion = "I agree to receive DataArt's monthly marketing newsletter.",
    companySiteFormContactUsTrackingID = '',
    companySiteFormContactUsFieldShort,
    companySiteFormContactUsFieldLong,
    companySiteFormContactUsRequiredField,
    companySiteFormContactUsPhone: {
      companySiteFormContactUsPhoneError = 'Phone error',
      companySiteFormContactUsPhoneLabel = 'Phone Number',
    } = {},
    companySiteFormContactUsCompany = 'Company',
    companySiteFormContactUsJobTitle = 'Job Title',
    companySiteFormContactUsEmail: {
      companySiteFormContactUsEmailLabel = 'Business Email',
      companySiteFormContactUsEmailError = 'Email error',
    } = {},
    companySiteFormContactUsName = 'First Name',
    companySiteFormContactUsBtnText = 'Submit',
    companySiteFormContactUsSubscribePolicy = 'I have read and agree to the Privacy Policy.',
    companySiteFormContactUsLastName = 'Last Name',
    companySiteFormContactUsAddInfo = 'Your Message',
    companySiteFormContactUsSuccessText,
    companySiteFormContactUsSuccessTitle,
  } = companySiteForm?.companySiteFormContactUs || {};

  const schemaAll = schemaLetsTalkAll(
    companySiteFormContactUsEmailError,
    companySiteFormContactUsPhoneError,
    companySiteFormContactUsFieldShort,
    companySiteFormContactUsFieldLong,
    companySiteFormContactUsRequiredField
  );

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    job: '',
    company: '',
    phone: '',
    message: '',
    newsletter: false,
    privacyAgreement: false,
  };

  const {
    handleSubmit,
    isSubmitting,
    errors,
    dirty,
    handleBlur,
    handleChange,
    setFieldValue,
    values,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: schemaAll,
    onSubmit: async (values, actions) => {
      try {
        let token = null;
        if (recaptchaKey) token = await reCaptchaRef.current?.executeAsync();
        const body = {
          ...values,
          pageId,
          cameFrom,
          utm,
          [`${recaptchaFormKey}`]: token,
          trackingId: companySiteFormContactUsTrackingID,
        };
        await mutationLocationsSalesNewForm({ body });

        setSuccessForm(true);
        actions.resetForm();
        sendDataToGTM({
          event: 'send_form',
          form_name: companySiteFormContactUsTrackingID || 'contact_us_form',
          label: values.email,
          page_path: pagePath,
        });
      } catch (error) {
        error instanceof Error
          ? console.error(error.message)
          : console.error(error);
      }
    },
  });

  const isPhoneError = !!(touched.phone && errors.phone);
  const isFormBuilderPopup = !!(form && form.successPopup);
  const reCaptchaAvailability =
    !!recaptchaKey && process.env.NODE_ENV === 'production';

  return (
    <div
      className={cnContentBuilderTemplate({
        type: typeNumber,
        noMember: !member,
      })}
    >
      <div
        className={cnContentBuilderTemplate('Form-Overlay', {
          active: isSubmitting,
        })}
      />
      <Grid
        id={contentId || 'expert-form-01'}
        breakpoints={{
          desktop: { cols: 12, gap: 'xl' },
          tablet: { cols: 8, gap: 'm' },
        }}
      >
        <GridItem
          breakpoints={{ desktop: { col: 5 }, tablet: { col: 4 } }}
          className={cnContentBuilderTemplate('Left')}
        >
          {title && (
            <Typography.Title
              size="lg"
              dangerouslySetInnerHTML={{ __html: title }}
              as="span"
            />
          )}
          {text && <Article content={text} />}
          {member && (
            <MemberCard
              {...member}
              view="ghost"
              routePath={aliases.TEAM_MEMBER}
              socials
            />
          )}
        </GridItem>

        <GridItem
          breakpoints={{
            desktop: { col: 6, colStart: 7 },
            tablet: { col: 4 },
          }}
          className={'GridItem-Form'}
        >
          {form ? (
            <FormBuilder
              form={form}
              cameFrom={cameFrom}
              utm={utm}
              dictionariesForm={dictionariesForm}
              setSuccessForm={setSuccessForm}
              // recaptchaKey={recaptchaKey}
              onSubmitForm={async body =>
                await mutationMainAllForm({
                  body: {
                    ...body,
                    pageId,
                    cameFrom,
                    [`${recaptchaFormKey}`]: body?.token,
                  },
                })
              }
            />
          ) : (
            <>
              <form
                onSubmit={handleSubmit}
                className={cnContentBuilderTemplate('Form', {})}
                id={id}
              >
                <div className={cnContentBuilderTemplate('Inner')}>
                  <div className={cnContentBuilderTemplate('Body')}>
                    <TextField
                      required
                      name="firstName"
                      label={companySiteFormContactUsName}
                      view="outline"
                      width="full"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values.firstName}
                      caption={
                        touched.firstName && errors.firstName
                          ? errors.firstName
                          : undefined
                      }
                      status={
                        touched.firstName && errors.firstName
                          ? 'alert'
                          : undefined
                      }
                    />
                    <TextField
                      required
                      name="lastName"
                      label={companySiteFormContactUsLastName}
                      view="outline"
                      width="full"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values.lastName}
                      caption={
                        touched.lastName && errors.lastName
                          ? errors.lastName
                          : undefined
                      }
                      status={
                        touched.lastName && errors.lastName
                          ? 'alert'
                          : undefined
                      }
                    />
                    <TextField
                      required
                      name="email"
                      label={companySiteFormContactUsEmailLabel}
                      view="outline"
                      width="full"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values.email}
                      caption={
                        touched.email && errors.email ? errors.email : undefined
                      }
                      status={
                        touched.email && errors.email ? 'alert' : undefined
                      }
                    />
                    <TextField
                      name="job"
                      label={companySiteFormContactUsJobTitle}
                      view="outline"
                      width="full"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values.job}
                      caption={
                        touched.job && errors.job ? errors.job : undefined
                      }
                      status={touched.job && errors.job ? 'alert' : undefined}
                    />
                    <TextField
                      required
                      name="company"
                      label={companySiteFormContactUsCompany}
                      view="outline"
                      width="full"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values.company}
                      caption={
                        touched.company && errors.company
                          ? errors.company
                          : undefined
                      }
                      status={
                        touched.company && errors.company ? 'alert' : undefined
                      }
                    />
                    <div className="TextField TextField_labelPosition_top TextField_size_m TextField_view_outline TextField_width_full">
                      <label
                        htmlFor="phone"
                        className="Text Text_size_s Text_view_secondary FieldLabel TextField-Label TextField-Label_labelPosition_top"
                      >
                        {companySiteFormContactUsPhoneLabel}
                      </label>
                      <PhoneInput
                        id="phone"
                        name="phone"
                        value={values.phone}
                        onChange={e => {
                          setFieldValue('phone', e);
                        }}
                        onBlur={handleBlur}
                        international
                        initialValueFormat="national"
                        countryCallingCodeEditable={false}
                        defaultCountry="US"
                        error={
                          isPhoneError
                            ? companySiteFormContactUsPhoneError
                            : undefined
                        }
                      />
                      {isPhoneError && (
                        <span className="Text Text_size_s Text_view_alert FieldCaption TextField-Caption">
                          {companySiteFormContactUsPhoneError}
                        </span>
                      )}
                    </div>
                    <TextField
                      required
                      type="textarea"
                      name="message"
                      label={companySiteFormContactUsAddInfo}
                      view="outline"
                      width="full"
                      onChange={({ e }) => handleChange(e)}
                      onBlur={handleBlur}
                      value={values.message}
                      caption={
                        touched.message && errors.message
                          ? errors.message
                          : undefined
                      }
                      status={
                        touched.message && errors.message ? 'alert' : undefined
                      }
                    />
                  </div>
                  <div className={cnContentBuilderTemplate('Bottom')}>
                    <div className={cnContentBuilderTemplate('Checkboxes')}>
                      {companySiteFormContactUsSubscribtion && (
                        <div className={cnContentBuilderTemplate('Checkbox')}>
                          <Checkbox
                            name="newsletter"
                            label={companySiteFormContactUsSubscribtion}
                            onChange={({ e }) => handleChange(e)}
                            onBlur={handleBlur}
                            checked={values.newsletter}
                          />
                        </div>
                      )}
                      <div className={cnContentBuilderTemplate('Checkbox')}>
                        <Checkbox
                          required
                          name="privacyAgreement"
                          label={companySiteFormContactUsSubscribePolicy}
                          onChange={({ e }) => handleChange(e)}
                          onBlur={handleBlur}
                          checked={values.privacyAgreement}
                        />
                      </div>
                    </div>
                    <div className={cnContentBuilderTemplate('Button')}>
                      <Button
                        type="submit"
                        id={companySiteFormContactUsTrackingID}
                        label={companySiteFormContactUsBtnText}
                        iconRight={IconArrowRight}
                        disabled={
                          isSubmitting || !!Object.keys(errors).length || !dirty
                        }
                      />
                      {reCaptchaAvailability && (
                        <ReCAPTCHA
                          ref={reCaptchaRef}
                          sitekey={recaptchaKey}
                          size="invisible"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
          <Modal
            view="secondary"
            hasOverlay
            onOpen={() => {
              document.body.style.overflow = 'hidden';
            }}
            onClose={() => {
              document.body.style.overflow = 'unset';
            }}
            isOpen={successForm}
            onOverlayClick={(): void => {
              setSuccessForm(false);
              document.body.style.overflow = 'unset';
            }}
            onEsc={(): void => {
              setSuccessForm(false);
              document.body.style.overflow = 'unset';
            }}
            showCloseButton
            inverted
          >
            <div className={cnContentBuilderTemplate('ModalSuccess')}>
              {isFormBuilderPopup ? (
                <>
                  {form.successPopup.title && (
                    <Typography.Title
                      size="lg"
                      className={cnContentBuilderTemplate('ModalSuccess-Title')}
                    >
                      {form.successPopup.title}
                    </Typography.Title>
                  )}
                  {form.successPopup.text && (
                    <Typography.Text
                      className={cnContentBuilderTemplate(
                        'ModalSuccess-SubTitle'
                      )}
                    >
                      {form.successPopup.text}
                    </Typography.Text>
                  )}
                  <Button
                    label={form.successPopup.link?.title}
                    iconRight={IconArrowRight}
                    view="secondary"
                    className={cnContentBuilderTemplate(
                      'ModalSuccess-Download'
                    )}
                    onClick={() =>
                      window.open(
                        form.successPopup.link?.link,
                        form.successPopup.link?.targetBlank ? '_blank' : '_self'
                      )
                    }
                  />
                </>
              ) : (
                <>
                  {companySiteFormContactUsSuccessTitle && (
                    <Typography.Title
                      size="lg"
                      className={cnContentBuilderTemplate('ModalSuccess-Title')}
                    >
                      {companySiteFormContactUsSuccessTitle}
                    </Typography.Title>
                  )}
                  {companySiteFormContactUsSuccessText && (
                    <Typography.Text
                      className={cnContentBuilderTemplate(
                        'ModalSuccess-SubTitle'
                      )}
                    >
                      {companySiteFormContactUsSuccessText}
                    </Typography.Text>
                  )}
                </>
              )}
            </div>
          </Modal>
        </GridItem>
      </Grid>
    </div>
  );
};

export default ContentBuilderTemplate;
